<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('SaveExpenses') }}
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-2 d-none d-md-block"
          @click="isAddNewSaveExpenses =true"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span class="d-none d-sm-block"> {{ $t('increase_the_cost') }}</span>
        </v-btn>
        <v-btn
          color="primary"
          class="me-2 d-block d-md-none"
          fab
          outlined
          small
          @click="isAddNewSaveExpenses =true"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
        <router-link
          :to="{ name: 'Save-expenses-group' }"
          class="text-decoration-none"
        >
          <v-btn
            color="info"
            class="d-none d-md-block"
          >
            <v-icon class="me-1">
              {{ icons.mdiFormatListBulleted }}
            </v-icon> <span class="d-none d-sm-block"> {{ $t('expense_category') }}</span>
          </v-btn>
          <v-btn
            color="info"
            class="d-block d-md-none"
            fab
            outlined
            small
          >
            <v-icon>{{ icons.mdiFormatListBulleted }}</v-icon>
          </v-btn>
        </router-link>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-autocomplete
            v-model="shop_bankgroup_id"
            outlined
            dense
            :items="dataSaveExpensesGroupList"
            :label="$t('expense_category')"
            item-text="shop_bankgroup_name"
            :no-data-text="$t('no_information')"
            item-value="shop_bankgroup_id"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-dialog
            ref="dateStart"
            v-model.trim="isShowDate1"
            :return-value.sync="date_start"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model.trim="date_start"
                :label="$t('date')"
                outlined
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="date_start"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.dateStart.save(date_start)"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="isShowDate1 = false"
              >
                {{ $t("cancel") }}
              </v-btn>

              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="3"
        >
          <v-dialog
            ref="dateEnd"
            v-model.trim="isShowDate2"
            :return-value.sync="date_end"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model.trim="date_end"
                :label="$t('to')"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <v-tooltip
                    color="#212121"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="date_start = '';date_end = ''"
                      >
                        {{ icons.mdiCalendarBlank }}
                      </v-icon>
                    </template>
                    <span>{{ $t("all_dates") }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="date_end"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>

              <v-btn
                text
                color="primary"
                @click="$refs.dateEnd.save(date_end)"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="isShowDate2 = false"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.shop_bankpay_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.shop_bankpay_date`]="{ item }">
          <ConverseDate :date="item.shop_bankpay_date" />
        </template>
        <template v-slot:[`item.shop_bankpay_name`]="{ item }">
          {{ item.shop_bankpay_name }} ({{ item.shop_bankpay_code }})
        </template>
        <template v-slot:[`item.shop_bankpay_wallet`]="{ item }">
          {{ item.shop_bankpay_wallet | formatPrice }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                :disabled="item.shop_bankgroup_name == 'ขอคืนเงิน'"
                @click="dataEdit = item ;isEditSaveExpenses = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
    </v-card>
    <AddNewSaveExpenses
      v-model="isAddNewSaveExpenses"
      @onAdd="fetchDataTable"
    />
    <EditSaveExpenses
      v-model="isEditSaveExpenses"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
  </div>
</template>

<script>
import {
  mdiPlus, mdiFormatListBulleted, mdiCalendarBlank, mdiPencilOutline,
} from '@mdi/js'
import useSaveExpenses from './useSaveExpenses'
import { formatPrice } from '@/plugins/filters'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import AddNewSaveExpenses from './AddNewSaveExpenses.vue'
import EditSaveExpenses from './EditSaveExpenses.vue'

export default {
  components: {
    ConverseDate,
    AddNewSaveExpenses,
    EditSaveExpenses,
  },
  filters: {
    formatPrice,
  },
  setup() {
    const {
      isAddNewSaveExpenses,
      isEditSaveExpenses,
      dataEdit,
      isShowDate1,
      isShowDate2,
      searchtext,
      date_start,
      date_end,
      shop_bankgroup_id,
      dataSaveExpensesGroupList,
      dataTableList,
      loading,
      options,
      columns,
      fetchDataTable,
    } = useSaveExpenses()

    return {
      isAddNewSaveExpenses,
      isEditSaveExpenses,
      dataEdit,
      isShowDate1,
      isShowDate2,
      searchtext,
      date_start,
      date_end,
      shop_bankgroup_id,
      dataSaveExpensesGroupList,
      dataTableList,
      loading,
      options,
      columns,
      fetchDataTable,
      icons: {
        mdiPlus, mdiFormatListBulleted, mdiCalendarBlank, mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
