import saveExpenses from '@/api/receipt/saveExpenses'
import saveExpensesGroup from '@/api/receipt/saveExpensesGroup'
import { i18n } from '@/plugins/i18n'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useSaveExpenses() {
  const moment = require('moment')

  const searchtext = ref('')
  const date_start = ref(moment(new Date()).format('YYYY-MM-DD'))
  const date_end = ref(moment(new Date()).format('YYYY-MM-DD'))
  const shop_bankgroup_id = ref('')
  const dataSaveExpensesGroupList = ref([])
  const isShowDate1 = ref(false)
  const isShowDate2 = ref(false)

  const dataTableList = ref([])
  const loading = ref(false)
  const options = ref({})
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'shop_bankpay_id',
      width: 50,
    },
    {
      text: i18n.t('expense_category'),
      value: 'shop_bankgroup_name',
      width: 170,
    },
    {
      text: i18n.t('list'),
      value: 'shop_bankpay_name',
      width: 250,
    },
    {
      text: i18n.t('date'),
      value: 'shop_bankpay_date',
      width: 120,
    },
    {
      text: i18n.t('listener'),
      value: 'user_fullname',
      width: 250,
    },
    {
      text: i18n.t('amount'),
      value: 'shop_bankpay_wallet',
      align: 'end',
      width: 150,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      align: 'center',
      width: 120,
    },
  ])

  const { saveExpensesGroupList } = saveExpensesGroup
  const { saveExpensesList } = saveExpenses

  const isAddNewSaveExpenses = ref(false)
  const isEditSaveExpenses = ref(false)
  const dataEdit = ref({})

  onMounted(() => {
    getSaveExpensesGroupList()
    fetchDataTable()
  })

  const getSaveExpensesGroupList = () => {
    saveExpensesGroupList({
      searchtext: '',
      shop_bankgroup_status_id: 1,
    }).then(res => {
      dataSaveExpensesGroupList.value = res
      dataSaveExpensesGroupList.value.unshift({
        shop_bankgroup_id: '',
        shop_bankgroup_name: i18n.t('category_cost_all'),
      })
    })
  }

  const fetchDataTable = () => {
    loading.value = true
    saveExpensesList({
      searchtext: searchtext.value,
      date_start: date_start.value,
      date_end: date_end.value,
      shop_bankgroup_id: shop_bankgroup_id.value,
    }).then(res => {
      dataTableList.value = res
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    })
  }

  watch([searchtext, date_start, date_end, shop_bankgroup_id], () => {
    fetchDataTable()
  })

  return {
    isAddNewSaveExpenses,
    isEditSaveExpenses,
    dataEdit,
    isShowDate1,
    isShowDate2,
    searchtext,
    date_start,
    date_end,
    shop_bankgroup_id,
    dataSaveExpensesGroupList,
    dataTableList,
    loading,
    options,
    columns,
    fetchDataTable,
  }
}
