<template>
  <div>
    <v-dialog
      v-model="isAddNewSaveExpenses"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t("add_expense_category") }}
        </v-card-title>
        <v-form
          ref="formAddNewSaveExpenses"
          @submit.prevent="createSaveExpenses"
        >
          <v-card-text>
            <v-autocomplete
              v-model="dataAdd.shop_bankgroup_id"
              :items="dataSaveExpensesGroupList"
              :label="$t('expense_category')"
              item-text="shop_bankgroup_name"
              :rules="[required]"
              item-value="shop_bankgroup_id"
              outlined
              dense
            ></v-autocomplete>
            <v-text-field
              v-model="dataAdd.shop_bankpay_name"
              tabindex="0"
              :rules="[required]"
              class="mt-3"
              :label="$t('list')"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="dataAdd.shop_bankpay_code"
              :label="$t('reference_number')"
              class="mt-3"
              outlined
              :rules="[required]"
              dense
            ></v-text-field>
            <v-dialog
              ref="date"
              v-model="isShowDate"
              :return-value.sync="dataAdd.shop_bankpay_date"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dataAdd.shop_bankpay_date"
                  dense
                  :rules="[required]"
                  outlined
                  readonly
                  :label="$t('record_date')"
                  class="mt-3"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dataAdd.shop_bankpay_date"
                class="set-font"
                :locale="$i18n.locale"
                :rules="[required]"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.date.save(dataAdd.shop_bankpay_date)"
                >
                  {{ $t("confirm") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="isShowDate = false"
                >
                  {{ $t("cancel") }}
                </v-btn>

                <v-spacer></v-spacer>
              </v-date-picker>
            </v-dialog>
            <v-text-field
              v-model="dataAdd.shop_bankpay_wallet"
              :label="$t('amount_baht')"
              :rules="[required]"
              class="mt-3"
              type="number"
              outlined
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-save-expenses',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import saveExpensesGroup from '@/api/receipt/saveExpensesGroup'
import saveExpenses from '@/api/receipt/saveExpenses'
import { required } from '@/@core/utils/validation'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewSaveExpenses',
    event: 'update:is-add-new-save-expenses',
  },
  props: {
    isAddNewSaveExpenses: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const moment = require('moment')
    const dataAdd = ref({})
    const dataSaveExpensesGroupList = ref([])
    const isShowDate = ref(false)
    const formAddNewSaveExpenses = ref(null)
    const loading = ref(false)

    const { saveExpensesGroupList } = saveExpensesGroup
    const { saveExpensesAdd } = saveExpenses

    const getSaveExpensesGroupList = () => {
      saveExpensesGroupList({
        searchtext: '',
        shop_bankgroup_status_id: 1,
      }).then(res => {
        dataSaveExpensesGroupList.value = res
        dataAdd.value.shop_bankgroup_id = res[res.length - 2].shop_bankgroup_id
      })
    }

    const createSaveExpenses = () => {
      const isFormValid = formAddNewSaveExpenses.value.validate()
      if (!isFormValid) return
      loading.value = true
      saveExpensesAdd(dataAdd.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-add-new-save-expenses', false)
        loading.value = false
        emit('onAdd')
      })
    }

    watch(() => props.isAddNewSaveExpenses, value => {
      if (value) {
        dataAdd.value = {}
        getSaveExpensesGroupList()
        dataAdd.value.shop_bankpay_date = moment(new Date()).format('YYYY-MM-DD')
      }
    })

    return {
      isShowDate,
      dataAdd,
      dataSaveExpensesGroupList,
      createSaveExpenses,
      formAddNewSaveExpenses,
      loading,
      required,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
